import React from "react";
import { Route, Routes } from "react-router-dom";
import SuperDashboard from "../pages/SuperAdmin/SuperDashboard";
import CommandCenter from "../pages/SuperAdmin/CommandCenter";
import BookingDashboard from "../pages/SuperAdmin/BookingDashboard";
import AssignRole from "../pages/SuperAdmin/AssignRole";
import UserList from "../pages/SuperAdmin/UserList";
import { AnimatePresence } from "framer-motion";
import DriverList from "../pages/Driver/DriverList";
import ChatSupport from "../pages/SuperAdmin/ChatSupport";
import FundsManagement from "../pages/SuperAdmin/FundsManagement";
import AddControlCenter from "../pages/SuperAdmin/AddControlCenter";
import DriverProfile from "../pages/Driver/DriverProfile";
import DriverDocument from "../pages/Driver/DriverDocument";
import DriverAccount from "../pages/Driver/DriverAccount";
import CreateSubRole from "../pages/CommandCenter/CreateSubRole";
import RoleList from "../pages/CommandCenter/RoleList";
import DriverRating from "../pages/Driver/DriverRating";
import DriverTransection from "../pages/Driver/DriverTransection";
import RatesSharing from "../pages/PriceAndCities/RatesSharing";
import RatesPersonal from "../pages/PriceAndCities/RatesPersonal";
import RatesToAirport from "../pages/PriceAndCities/RatesToAirport";
import RatesFromAirport from "../pages/PriceAndCities/RatesFromAirport";
import RatesIntercity from "../pages/PriceAndCities/RatesIntercity";
import RatesDriveTest from "../pages/PriceAndCities/RatesDriveTest";
function SuperAdminRoutes() {
  return (
    <AnimatePresence mode="wait">
      <Routes>
        <Route path="/" element={<SuperDashboard />} />
        <Route path="/command-center" element={<CommandCenter />} />
        <Route path="/booking-dashboard" element={<BookingDashboard />} />
        <Route path="/assign-role" element={<AssignRole />} />
        <Route path="/role-list" element={<RoleList />} />
        <Route path="/create-sub-role" element={<CreateSubRole />} />
        <Route path="/user-list" element={<UserList />} />
        <Route path="/driver-list" element={<DriverList />} />
        <Route path="/driver-profile/:id" element={<DriverProfile />} />
        <Route path="/driver-document/:id" element={<DriverDocument />} />
        <Route path="/driver-account/:id" element={<DriverAccount />} />
        <Route path="/driver-rating/:id" element={<DriverRating />} />
        
        <Route path="/driver-transection-history/:id" element={<DriverTransection />} />
        <Route path="/price-city-rate-sharing" element={<RatesSharing />} />
        <Route path="/price-city-rate-personal" element={<RatesPersonal />} />
        <Route path="/price-city-rate-to-airport" element={<RatesToAirport />} />
        <Route path="/price-city-rate-from-airport" element={<RatesFromAirport />} />
        <Route path="/price-city-rate-drive-test" element={<RatesDriveTest />} />
        <Route path="/price-city-rate-intercity" element={<RatesIntercity />} />
        <Route path="/chat-support" element={<ChatSupport />} />
        <Route path="/funds-management" element={<FundsManagement />} />
        <Route path="/add-control-center" element={<AddControlCenter />} />
      </Routes>
    </AnimatePresence>
  );
}

export default SuperAdminRoutes;
