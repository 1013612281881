import React, {useState, useEffect} from "react";
import { getDriverById, editDriverProfile } from "../../services/SuperAdmin/driver.service";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function DriverDocument() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const navItem = [
    {
      title: "Profile",
      path: "/driver-profile",
    },
    {
      title: "Document",
      path: "/driver-document",
    },
    {
      title: "Account",
      path: "/driver-account",
    },
    {
      title: "Rating",
      path: "/driver-rating",
    },
    {
      title: "Transection History",
      path: "/driver-transection-history",
    },
  ];
  const tableHeader = ["Document Name", "Image", "View", "Download", "Action", "Status"];
  const [driverDetails, setDriverDetails] = useState();
  
  const [loader, setLoader] = useState(false);
  const handleGetDriverById = async () => {
    setLoader(true);
    try {
      let response = await getDriverById(JSON.parse(localStorage.getItem("access_token")), params?.id);
      if (response.data.statusCode == "200") {
        setDriverDetails(response.data?.data);
        
      }
    } catch (error) {}
    setLoader(false);
  };
  useEffect(() => {
    handleGetDriverById();
  }, []);
  const driverDocument = [
    {
      title:"Driving Licence",
      image: driverDetails?.licence_image,
      exp:driverDetails?.licence_expiry,
      key:"licence_status"
    },
    {
      title:"Ownership",
      image: driverDetails?.ownership_image,
      key:"ownership_status"
    },
    {
      title:"Insurance",
      image: driverDetails?.insurance_image,
      exp:driverDetails?.insurance_expiry,
      key:"insurance_status"
    },
    {
      title:"Direct Deposite Form",
      image: driverDetails?.licence_image
    },
  ]

  const handleDownloadDocument =  (fileUrl) => {
    alert("Work In Progress")
  };
  
  const handleSubmitDriverProfile = async (keyName, value) => {
    const formData = { [keyName]: value };
    try {
      let response = await editDriverProfile(JSON.parse(localStorage.getItem("access_token")), formData, params.id );
      if(response?.data?.statusCode == "200"){
        toast.success("Driver updated successfully");
        handleGetDriverById()
      }
    } catch (error) {
      console.error("Error submitting driver profile:", error);
      toast.error("An error occurred while updating the profile.");
    } 
  };
  
  
  return (
    <div className="driverDetailMainDiv">
      <div className="driverNav row m-0 p-0">
        {navItem?.map((v, i) => {
          return (
            <div
              onClick={() => navigate(v?.path + "/" + params?.id)}
              className={
                "col-2 driverNavItem d-flex justify-content-center " +
                (location.pathname.includes(v?.path) && " driverSelectedNav")
              }
            >
              <p className="mb-0">{v.title}</p>
            </div>
          );
        })}
      </div>
      <div className={"driverDetailBody " + (location.pathname.includes("driver-profile") && " borderLeft0")}>
        <div className="filterBg">
          <div className="row m-0  driverDocumentTableHeader">
            {tableHeader?.map((v, i) => {
              return (
                <div className="col-2 m-0 p-0 d-flex justify-content-center align-items-center">
                  <p className="mb-0">{v}</p>
                </div>
              );
            })}
          </div>
          {driverDocument?.map((v, i) => {
            return (
              <div className="row  driverDocumentTableRow ">
                <div className="col-2 d-flex justify-content-center align-items-center">
                  <div className="text-center">
                    <p className="mb-0">{v?.title}</p>
                    {v?.exp &&  <small>(Exp : {v?.exp})</small>}
                   
                  </div>
                </div>
                <div className="col-2 d-flex justify-content-center align-items-center">
                  <a href={"https://api.faizah.in/storage/"+ v?.image} target="_blank"><img style={{height:"40px", width:"40px"}} src={"https://api.faizah.in/storage/"+ v?.image} className="img-fluid"/></a>
                  
                </div>
                <div className="col-2 d-flex justify-content-center align-items-center">
                  <img style={{height:"20px", width:"20px"}} src="https://cdn-icons-png.flaticon.com/128/535/535193.png" className="img-fluid"/>
                </div>
                <div className="col-2 d-flex justify-content-center align-items-center">
                  <button style={{background:"#191919"}} onClick={()=>handleDownloadDocument("https://api.faizah.in/storage/"+ v?.image)}>Download</button>
                </div>
                <div className="col-2 d-flex justify-content-center align-items-center">
                  <select className="" style={{background:"#F6F6F6"}} onChange={(e)=>handleSubmitDriverProfile(v?.key, e.target.value)}>
                    <option value="0">Re Issue</option>
                    <option value="1">Approved</option>
                  </select>
                </div>
                <div className="col-2 d-flex justify-content-center align-items-center">
                  {v?.key == "licence_status" && <>{driverDetails?.licence_status ===1 ? <button style={{background:"#139F01"}}>Verified</button>:<button style={{background:"#ED2236"}}>Pending</button>}</> }
                  {v?.key == "ownership_status" && <>{driverDetails?.ownership_status ===1 ? <button style={{background:"#139F01"}}>Verified</button>:<button style={{background:"#ED2236"}}>Pending</button>}</> }
                  {v?.key == "insurance_status" && <>{driverDetails?.insurance_status ===1 ? <button style={{background:"#139F01"}}>Verified</button>:<button style={{background:"#ED2236"}}>Pending</button>}</> }
                  
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <ToastContainer/>
    </div>
  );
}

export default DriverDocument;
