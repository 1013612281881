import React from "react";
import Sidebar from "../../components/Sidebar";
import { useNavigate, useLocation, useParams } from "react-router-dom";
function DriverAccount() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const navItem = [
    {
      title: "Profile",
      path: "/driver-profile",
    },
    {
      title: "Document",
      path: "/driver-document",
    },
    {
      title: "Account",
      path: "/driver-account",
    },
    {
      title: "Rating",
      path: "/driver-rating",
    },
    {
      title: "Transection_History",
      path: "/driver-transection-history",
    },
  ];
  const tableHeader = ["Document Name", "Image", "View", "Download", "Action", "Status"];
  return (
    <div className="driverDetailMainDiv">
      <div className="driverNav row m-0 p-0">
        {navItem?.map((v, i) => {
          return (
            <div
              onClick={() => navigate(v?.path + "/" + params?.id)}
              className={
                "col-2 driverNavItem d-flex justify-content-center " +
                (location.pathname.includes(v?.path) && " driverSelectedNav")
              }
            >
              <p className="mb-0">{v.title}</p>
            </div>
          );
        })}
      </div>
      <div className={"driverDetailBody " + (location.pathname.includes("driver-profile") && " borderLeft0")}>
        <div className="filterBg">
          <div className="row d-flex justify-content-between m-0 p-0">
          <div className="col-7 m-0 p-0">
              <div className="form-check d-flex align-items-center">
                <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault" />
                <h5 className="mb-0">Interac E-transfer</h5>
              </div>
              <div className="mb-2">
                <label>Email Address : </label>
                <input className="form-control" value="testdriver@gmail.com" readOnly/>
              </div>
            </div>
            <div className="col-7 m-0 mt-5 p-0">
              <div className="form-check  d-flex align-items-center">
                <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault" />
                <h5 className="mb-0">Direct Deposite Details</h5>
              </div>
            </div>

            <div className="col-7 m-0 p-0">
              <div className="mb-2">
                <label>Customer Name : </label>
                <input className="form-control" value="Test Driver" />
              </div>
              <div className="mb-2">
                <label>Transit Number : </label>
                <input className="form-control"  value="12dr467"/>
              </div>
              <div className="mb-2">
                <label>Institution Number : </label>
                <input className="form-control" value="12dr467"/>
              </div>
              <div className="mb-2">
                <label>Account Number : </label>
                <input className="form-control" value="12dr467"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DriverAccount;
