import React from "react";


function ChatSupport() {
  return (
    <div className="vh-100 d-flex justify-content-center align-items-center">
        <h1>Work In Progress</h1>
    </div>
  );
}

export default ChatSupport;
