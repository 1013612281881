import React from "react";
import Sidebar from "../../components/Sidebar";
import CustomRangeInput from "../../components/CustomRangeInput";

function CommandCenter() {
  return(
    <div className="w-100">
     <div className="d-flex justify-content-between p-2 align-items-center">
            <div className="d-flex">
              <h5 className="mb-0">Role Manager</h5>
            </div>
          </div>
         <div className="shadow-sm roleMain mt-3  border">
          <div className="row m-0 p-3">
            <div className="col-5 m-0 p-0">
              <h6 className="mb-0">Actions</h6>
            </div>
            <div className="col-1 m-0 p-0">
              <h6 className="mb-0">Member</h6>
            </div>
            <div className="col-1 m-0 p-0">
              <h6 className="mb-0">Member</h6>
            </div>
            <div className="col-1 m-0 p-0">
              <h6 className="mb-0">Menager</h6>
            </div>
            <div className="col-1 m-0 p-0">
              <h6 className="mb-0">Admin</h6>
            </div>
            <div className="col-1 m-0 p-0">
              <h6 className="mb-0">Member</h6>
            </div>
            <div className="col-1 m-0 p-0">
              <h6 className="mb-0">Member</h6>
            </div>
            <div className="col-1 m-0 p-0">
              <h6 className="mb-0">Action</h6>
            </div>
          </div>
          <div style={{background:"#EDEDED"}} className=" mt-2 mb-2 mx-0 p-2">
            <h6>Booking Dashboard</h6>
          </div>
          <div className="row m-0 p-3">
            <div className="col-5 m-0 p-0">
              <p className="mb-0">Sharing New Booking</p>
            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={true}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={false}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={false}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={false}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={true}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={true}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={false}/>

            </div>
            
          </div>
          <div className="row m-0 p-3">
            <div className="col-5 m-0 p-0">
              <p className="mb-0">Sharing New Booking</p>
            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={true}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={false}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={false}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={false}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={true}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={true}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={false}/>

            </div>
            
          </div>
          <div className="row m-0 p-3">
            <div className="col-5 m-0 p-0">
              <p className="mb-0">Sharing New Booking</p>
            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={true}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={false}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={false}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={false}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={true}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={true}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={false}/>

            </div>
            
          </div>
          <div className="row m-0 p-3">
            <div className="col-5 m-0 p-0">
              <p className="mb-0">Sharing New Booking</p>
            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={true}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={false}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={false}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={false}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={true}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={true}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={false}/>

            </div>
            
          </div>
          <div style={{background:"#EDEDED"}} className=" mt-2 mb-2 mx-0 p-2">
            <h6>Pricing And Cities</h6>
          </div>
          <div className="row m-0 p-3">
            <div className="col-5 m-0 p-0">
              <p className="mb-0">Sharing New Booking</p>
            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={true}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={false}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={false}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={false}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={true}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={true}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={false}/>

            </div>
            
          </div>
          <div className="row m-0 p-3">
            <div className="col-5 m-0 p-0">
              <p className="mb-0">Sharing New Booking</p>
            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={true}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={false}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={false}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={false}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={true}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={true}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={false}/>

            </div>
            
          </div>
          <div className="row m-0 p-3">
            <div className="col-5 m-0 p-0">
              <p className="mb-0">Sharing New Booking</p>
            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={true}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={false}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={false}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={false}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={true}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={true}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={false}/>

            </div>
            
          </div>
          <div className="row m-0 p-3">
            <div className="col-5 m-0 p-0">
              <p className="mb-0">Sharing New Booking</p>
            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={true}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={false}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={false}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={false}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={true}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={true}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={false}/>

            </div>
            
          </div>
          <div style={{background:"#EDEDED"}} className=" mt-2 mb-2 mx-0 p-2">
            <h6>Support Center</h6>
          </div>
          <div className="row m-0 p-3">
            <div className="col-5 m-0 p-0">
              <p className="mb-0">Sharing New Booking</p>
            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={true}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={false}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={false}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={false}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={true}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={true}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={false}/>

            </div>
            
          </div>
          <div className="row m-0 p-3">
            <div className="col-5 m-0 p-0">
              <p className="mb-0">Sharing New Booking</p>
            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={true}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={false}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={false}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={false}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={true}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={true}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={false}/>

            </div>
            
          </div>
          <div className="row m-0 p-3">
            <div className="col-5 m-0 p-0">
              <p className="mb-0">Sharing New Booking</p>
            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={true}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={false}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={false}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={false}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={true}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={true}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={false}/>

            </div>
            
          </div>
          <div className="row m-0 p-3">
            <div className="col-5 m-0 p-0">
              <p className="mb-0">Sharing New Booking</p>
            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={true}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={false}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={false}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={false}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={true}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={true}/>

            </div>
            <div className="col-1 m-0 p-0 justify-content-center">
            
            <CustomRangeInput value={false}/>

            </div>
            
          </div>
         </div>
    </div>
  )
  
}

export default CommandCenter;
