import React from "react";
import Sidebar from "../../components/Sidebar";
import { useNavigate, useLocation, useParams } from "react-router-dom";
function RatesPersonal() {
  const statics = [
    {
      title: "Categories",
    },
    {
      title: "Rates",
    },
    {
      title: "Surges",
    },
    {
      title: "Commission",
    },
    {
      title: "G.T.Charges",
    },
    {
      title: "Interac ID",
    },
    {
      title: "Payouts Info",
    },
    {
      title: "Cancel",
    },
  ];
  
  const tabSubHeading = [
    {
      title: "S No.",
    },
    {
      title: "Booking ID",
    },
    {
      title: "Source Address",
    },
    {
      title: "Destination Address",
    },
    {
      title: "Username",
    },
    {
      title: "Booking Date",
    },
    {
      title: "Booking Time",
    },
    {
      title: "Time Choice",
    },
    {
      title: "Total Amount",
    },
    {
      title: "Driver Earn",
    },
    {
      title: "Booking Placed",
    },
    {
      title: "Action",
    },
  ];
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const navItem = [
    {
      title: "Sharing",
      path: "/price-city-rate-sharing",
    },
    {
      title: "Personal",
      path: "/price-city-rate-personal",
    },
    {
      title: "To Airport",
      path: "/price-city-rate-to-airport",
    },
    {
      title: "From Airport",
      path: "/price-city-rate-from-airport",
    },
    {
      title: "Drive Test",
      path: "/price-city-rate-drive-test",
    },
    {
      title: "Intercity",
      path: "/price-city-rate-intercity",
    },
  ];
  
  return (
    <div className="priceAndCityMainDiv">
     <div className="row mx-0 p-0 mb-4 mainNav bgBlue">
         <div className="col-lg-6 row p-0 m-0">
           {statics?.slice(0, 4).map((v, i) => {
             return (
               <div className="col-lg-3 p-0">
                 <div className={"navItem " + (i == 1 ? " activeNavPriceNav" : " ")}>{v?.title}</div>
               </div>
             );
           })}
         </div>
         <div className="col-lg-6 row p-0 m-0">
           {statics?.slice(4, 8).map((v, i) => {
             return (
               <div className="col-lg-3 p-0">
                 <div className="navItem">{v?.title}</div>
               </div>
             );
           })}
         </div>
         <div className="col-lg-6"></div>
       </div>
       <div className="driverNav row m-0 p-0">
         {navItem?.map((v, i) => {
           return (
             <div
               onClick={() => navigate(v?.path)}
               className={
                 "col-2 driverNavItem d-flex justify-content-center " +
                 (location.pathname == v?.path && " priceAndCitySelectedNav")
               }
             >
               <p className="mb-0">{v.title}</p>
             </div>
           );
         })}
       </div>
       <div className={"priceAndCityDetailBody  " + (location.pathname.includes("price-city-rate-sharing") && " borderLeft0")}>
         <div className="row d-flex mt-2 justify-content-between  m-0 p-0">
           <div className="col-3">
             <select>
               <option>Select Source</option>
             </select>
           </div>
           <div className="col-3">
             <select>
               <option>Select Source</option>
             </select>
           </div>
           <div className="col-2">
             <input placeholder="Enter Price"/>
           </div>
           <div className="col-2">
             <button>Submit</button>
           </div>
         </div>
         <div className="row tableHeader mx-2 mt-4 " style={{background:"#DDDDDB", color:"#010101", borderRadius:"6px"}}>
         <div className="col-1 p-1 d-flex justify-content-center">
                 <h6 className="mb-0">Sr. No</h6>
               </div>
               <div className="col-2 p-1 d-flex justify-content-center">
                 <h6 className="mb-0">Source</h6>
               </div>
               <div className="col-3 p-1 d-flex justify-content-center">
                 <h6 className="mb-0">Destination</h6>
               </div>
               <div className="col-2 p-1 d-flex justify-content-center">
                 <h6 className="mb-0">Price</h6>
               </div>
               <div className="col-2 p-1 d-flex justify-content-center">
                 <h6 className="mb-0">Action</h6>
               </div>
         </div>
         {[1, 2, 3, 4, 5]?.map((v, i)=>{
           return(
             <div className="row  mx-2 my-3 priceCityRow">
         <div className="col-1 p-1 d-flex justify-content-center">
                 <p className="mb-0">{i+1}.</p>
               </div>
               <div className="col-2 p-1 d-flex justify-content-center">
                 <p className="mb-0" style={{color:"#025694"}}>Noida</p>
               </div>
               <div className="col-3 p-1 d-flex justify-content-center">
                 <p className="mb-0" style={{color:"#025694"}}>Delhi</p>
               </div>
               <div className="col-2 p-1 d-flex justify-content-center">
                 <p className="mb-0" style={{color:"#139F02"}}>20</p>
               </div>
               <div className="col-2 p-1 d-flex justify-content-center">
                 <select style={{width:"50%", background:"#353535" , color:"white"}}>
                   <option>Edit</option>
                   <option>Delete</option>
                   
                 </select>
               </div>
         </div>
           )
         })}
       </div>
       
    </div>
   );
}

export default RatesPersonal;
