import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import { getDriverById, deleteDriverById , editDriverProfile} from "../../services/SuperAdmin/driver.service";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function DriverProfile() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const navItem = [
    {
      title: "Profile",
      path: "/driver-profile",
    },
    {
      title: "Document",
      path: "/driver-document",
    },
    {
      title: "Account",
      path: "/driver-account",
    },
    {
      title: "Rating",
      path: "/driver-rating",
    },
    {
      title: "Transection History",
      path: "/driver-transection-history",
    },
  ];
  const [driverDetails, setDriverDetails] = useState();
  const [formData, setFormData] = useState();
  const [loader, setLoader] = useState(false);
  const handleGetDriverById = async () => {
    setLoader(true);
    try {
      let response = await getDriverById(JSON.parse(localStorage.getItem("access_token")), params?.id);
      if (response.data.statusCode == "200") {
        setDriverDetails(response.data?.data);
        setFormData(response.data?.data);
      }
    } catch (error) {}
    setLoader(false);
  };
  useEffect(() => {
    handleGetDriverById();
  }, []);
  const handleDeleteDriver = async (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this driver?");

    if (!confirmDelete) return; // Exit if the user clicks 'Cancel'

    try {
      const token = JSON.parse(localStorage.getItem("access_token"));
      const response = await deleteDriverById(token, id);

      if (response.data.statusCode === "200") {
        toast.success("Driver Deleted Succefully");
        setTimeout(() => {
          navigate("/driver-list");
        }, 1000);
      }
    } catch (error) {
      console.error("Error deleting driver:", error);
      toast.error("Failed to delete the driver. Please try again.");
    }
  };
  const [isFormEditable, setIsFormEditable] = useState(false);

  const handleEditDriverProfile = async () => {
    setIsFormEditable(false);
    toast.success("Start Editing the form");
  };

  const handleSubmitDriverProfile = async () => {
    const editFormData = new FormData();
  
    // Append only if the field has a value
    if (formData.first_name) {
      editFormData.append("first_name", formData.first_name);
    }
    if (formData.last_name) {
      editFormData.append("last_name", formData.last_name);
    }
    if (formData.email) {
      editFormData.append("email", formData.email);
    }
    if (formData.password) {
      editFormData.append("password", formData.password);
    }
    if (formData.vehicle_brand) {
      editFormData.append("vehicle_brand", formData.vehicle_brand);
    }
    if (formData.vehicle_colour) {
      editFormData.append("vehicle_colour", formData.vehicle_colour);
    }
    if (formData.vehicle_size) {
      editFormData.append("vehicle_size", formData.vehicle_size); 
    }
    if (formData.vehicle_date) {
      editFormData.append("vehicle_date", formData.vehicle_date); 
    }
    if (formData.status) {
      editFormData.append("status", formData.status); 
    }
    try {
      let response = await editDriverProfile(JSON.parse(localStorage.getItem("access_token")), editFormData, params.id );
      if(response?.data?.statusCode == "200"){
        toast.success("Driver updated successfully");
        handleGetDriverById()
      }
    } catch (error) {
      console.error("Error submitting driver profile:", error);
      toast.error("An error occurred while updating the profile.");
    } 
  };
  
  return (
    <div className="driverDetailMainDiv">
      <div className="driverNav row m-0 p-0">
        {navItem?.map((v, i) => {
          return (
            <div
              onClick={() => navigate(v?.path + "/" + params?.id)}
              className={
                "col-2 driverNavItem d-flex justify-content-center " +
                (location.pathname.includes(v?.path) && " driverSelectedNav")
              }
            >
              <p className="mb-0">{v.title}</p>
            </div>
          );
        })}
      </div>
      {loader ? (
        <div className="vh-100 d-flex justify-content-center align-items-center">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div className={"driverDetailBody " + (location.pathname.includes("driver-profile") && " borderLeft0")}>
          <div className="filterBg">
            <div className="row d-flex justify-content-between m-0 p-0">
              <div className="col-7 m-0 p-0">
                <div className="mb-2">
                  <label>First Name</label>
                  <input
                    className="form-control"
                    value={formData?.first_name}
                    readOnly={isFormEditable}
                    onChange={(e) => {
                      setFormData({ ...formData, first_name: e.target.value });
                    }}
                  />
                </div>
                <div className="mb-2">
                  <label>Last Name</label>
                  <input
                    className="form-control"
                    value={formData?.last_name}
                    readOnly={isFormEditable}
                    onChange={(e) => {
                      setFormData({ ...formData, last_name: e.target.value });
                    }}
                  />
                </div>
                <div className="mb-2">
                  <label>Email Address</label>
                  <input
                    className="form-control"
                    value={formData?.email}
                    readOnly={isFormEditable}
                    onChange={(e) => {
                      setFormData({ ...formData, email: e.target.value });
                    }}
                  />
                </div>
                <div className="mb-2">
                  <label>Phone Number</label>
                  <div className="d-flex">
                    <select
                      style={{ width: "16%", borderTopRightRadius: " 0", borderBottomRightRadius: "0px" }}
                      className="form-control"
                      value={formData?.country_code}
                      readOnly
                    >
                      <option value="1">+1</option>
                      <option value="91">+91</option>
                    </select>
                    <input
                      style={{ borderTopLeftRadius: " 0", borderBottomLeftRadius: "0px" }}
                      className="form-control"
                      value={formData?.contact}
                      readOnly
                    />
                  </div>
                </div>
                <div className="mb-2">
                  <label>Password</label>
                  <input
                    className="form-control"
                    value={formData?.password}
                    readOnly={isFormEditable}
                    onChange={(e) => {
                      setFormData({ ...formData, password: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className="col-4 m-0 d-flex justify-content-center p-0">
                <div className="w-100 my-auto">
                  <div className="d-flex justify-content-center">
                    <img
                      style={{ height: "125px", width: "125px", borderRadius: "50%" }}
                      src={
                        formData?.image
                          ? "https://api.faizah.in/storage/" + formData?.image
                          : "https://cdn-icons-png.flaticon.com/128/3687/3687416.png"
                      }
                    />
                  </div>
                  <div className="text-center">
                    <input className="imgInpDriverProfile" type="file" />
                  </div>
                  <div className="">
                    <label>Status</label>
                    <select
                      className="form-control"
                      value={formData?.status}
                      onChange={(e) => {
                        setFormData({ ...formData, status: e.target.value });
                      }}
                    >
                      <option value="Approve">Approve</option>
                      <option value="Disapprove">Disapprove</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="row  col-10 m-0 mt-2 p-0">
              <div className="col-6 m-0 pe-2 ps-0">
                <div className="mb-2">
                  <label>Vehicle Brand</label>
                  <input
                    className="form-control"
                    value={formData?.vehicle_brand}
                    onChange={(e) => {
                      setFormData({ ...formData, vehicle_brand: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className="col-6 m-0 pe-0 ps-2">
                <div className="mb-2">
                  <label>Vehicle (Year of Manufacture)</label>
                  <input
                    className="form-control"
                    value={formData?.vehicle_date}
                    onChange={(e) => {
                      setFormData({ ...formData, vehicle_date: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className="col-6 m-0 pe-2 ps-0">
                <div className="mb-2">
                  <label>Vehicle Color</label>
                  <input
                    className="form-control"
                    value={formData?.vehicle_colour}
                    onChange={(e) => {
                      setFormData({ ...formData, vehicle_colour: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className="col-6 m-0 pe-0 ps-2">
                <div className="mb-2">
                  <label>Vehicle Size</label>
                  <input
                    className="form-control"
                    value={formData?.vehicle_size}
                    onChange={(e) => {
                      setFormData({ ...formData, vehicle_size: e.target.value });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row mt-4 d-flex driverProfileActionBtns justify-content-around m-0 p-0">
              <div className="col-3">
                <button style={{ background: "" }} onClick={handleEditDriverProfile}>
                  Edit
                </button>
              </div>
              <div className="col-3">
                <button style={{ background: "#139F01" }} className="" onClick={() => handleSubmitDriverProfile()}>
                  Save
                </button>
              </div>
              <div className="col-3">
                <button
                  style={{ background: "#C30505" }}
                  className=""
                  onClick={() => handleDeleteDriver(driverDetails?.id)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
}

export default DriverProfile;
