import React, { useEffect } from "react";

function UserHistory({ userHistoryPopup, setUserHistoryPopup }) {
  const closeModal = () => {
    setUserHistoryPopup({
      show: false,
      data: null,
    });
  };
  <style>

  </style>
  return (
    userHistoryPopup.show && (
      <div
        className={`modal ${userHistoryPopup.show ? "show" : ""}`}
        tabIndex={-1}
        style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">History</h5>
              <button
                type="button"
                className="btn-close"
                onClick={closeModal}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p>This is the body of the modal.</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={closeModal}
              >
                Close
              </button>
              <button type="button" className="btn btn-primary">
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default UserHistory;
