import React from "react";
import Sidebar from "../components/Sidebar";
import { motion } from "framer-motion";
import SuperAdminRoutes from "../routes/SuperAdminRoutes";
import { useLocation } from "react-router-dom";

function SuperAdminLayout() {
  const location = useLocation();

  const fadeVariant = {
    initial: { opacity: 0.7 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  };

  return (
    <div className="mainBodyLayout ">
        <Sidebar />
        <motion.div
          key={location.pathname}  // ensures the fade effect triggers on page changes
          variants={fadeVariant}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{ duration: 0.5, ease: "easeInOut" }}
          className="row rightBodyContainerMain"
        >
          <SuperAdminRoutes />
        </motion.div>
      
    </div>
  );
}

export default SuperAdminLayout;
