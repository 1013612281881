import React from "react";
import Sidebar from "../../components/Sidebar";
import { useNavigate, useLocation, useParams } from "react-router-dom";
function DriverTransection() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const navItem = [
    {
      title: "Profile",
      path: "/driver-profile",
    },
    {
      title: "Document",
      path: "/driver-document",
    },
    {
      title: "Account",
      path: "/driver-account",
    },
    {
      title: "Rating",
      path: "/driver-rating",
    },
    {
      title: "Transection_History",
      path: "/driver-transection-history",
    },
  ];
  const tableHeader = ["Sr.No", "Account", "Transection Details", "Date", "Time", "New Balance"];
  return (
    <div className="driverDetailMainDiv">
      <div className="driverNav row m-0 p-0" >
        {navItem?.map((v, i) => {
          return (
            <div
              onClick={() => navigate(v?.path + "/" + params?.id)}
              className={
                "col-2 driverNavItem d-flex justify-content-center " +
                (location.pathname.includes(v?.path) && " driverSelectedNav")
              }
            >
              <p className="mb-0">{v.title}</p>
            </div>
          );
        })}
      </div>
      <div className={"driverDetailBody " + (location.pathname.includes("driver-profile") && " borderLeft0")}>
        <div className="filterBg">
          <div className="row m-0  driverDocumentTableHeader" style={{background:"#FDEEE7"}}>
            {tableHeader?.map((v, i) => {
              return (
                <div className="col-2 m-0 p-0 d-flex justify-content-center align-items-center">
                  <p className="mb-0" style={{color:"#312F2D"}}>{v}</p>
                </div>
              );
            })}
          </div>
          {[1, 2, 3, 4]?.map((v, i) => {
            return (
              <div className="row  driverDocumentTableRow ">
                <div className="col-2 d-flex justify-content-center align-items-center">
                  <div className="text-center">
                    <p className="mb-0">{i+1} .</p>
                    
                  </div>
                </div>
                <div className="col-2 d-flex justify-content-center align-items-center">
                 <p className="mb-0" style={{color:"#E63A3A"}}>-$80</p>
                </div>
                <div className="col-2 d-flex justify-content-center align-items-center">
                <p className="mb-0">Trip Payout</p>
                </div>
                <div className="col-2 d-flex justify-content-center align-items-center">
                 <p className="mb-0">11-10-2024</p> 
                </div>
                <div className="col-2 d-flex justify-content-center align-items-center">
                <p className="mb-0">10:20 PM</p> 
                </div>
                <div className="col-2 d-flex justify-content-center align-items-center">
                  <button style={{background:"#035791"}}>$100</button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default DriverTransection;
