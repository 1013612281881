import React from "react";
import Sidebar from "../../components/Sidebar";
import { useNavigate, useLocation, useParams } from "react-router-dom";
function DriverRating() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const navItem = [
    {
      title: "Profile",
      path: "/driver-profile",
    },
    {
      title: "Document",
      path: "/driver-document",
    },
    {
      title: "Account",
      path: "/driver-account",
    },
    {
      title: "Rating",
      path: "/driver-rating",
    },
    {
      title: "Transection History",
      path: "/driver-transection-history",
    },
  ];

  return (
    <div className="driverDetailMainDiv">
      <div className="driverNav row m-0 p-0">
        {navItem?.map((v, i) => {
          return (
            <div
              onClick={() => navigate(v?.path + "/" + params?.id)}
              className={
                "col-2 driverNavItem d-flex justify-content-center " +
                (location.pathname.includes(v?.path) && " driverSelectedNav")
              }
            >
              <p className="mb-0">{v.title}</p>
            </div>
          );
        })}
      </div>
      <div className={"driverDetailBody " + (location.pathname.includes("driver-profile") && " borderLeft0")}>
        <div className="filterBg">
          <div className="row m-0 p-0">
            <div className="col-6 m-0 p-0">
              <div className="ratingDiv d-flex justify-content-between align-items-center me-2">
                <div className="circleDivRating d-flex align-items-center justify-content-center">
                  <p className="mb-0">3.0</p>
                </div>
                <div className="starsDiv d-flex">
                  <img src="https://cdn-icons-png.flaticon.com/128/1828/1828884.png" className="img-fluid" />
                  <img src="https://cdn-icons-png.flaticon.com/128/1828/1828884.png" className="img-fluid" />
                  <img src="https://cdn-icons-png.flaticon.com/128/1828/1828884.png" className="img-fluid" />
                  <img src="https://cdn-icons-png.flaticon.com/128/1828/1828961.png" className="img-fluid" />
                  <img src="https://cdn-icons-png.flaticon.com/128/1828/1828961.png" className="img-fluid" />
                </div>
              </div>
              <div className="ratingLoadMainDiv me-2">
                <div className="row d-flex justify-content-between mx-0 my-4 p-0">
                  <div className="col-1 d-flex justify-content-start m-0 p-0">
                    <h5 className="mb-0">5 Star</h5>
                  </div>
                  <div className="col-9 m-0 p-0">
                    <div className="outerGrayBg">
                      <div className="innerGreenBg" style={{ width: "80%" }}></div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <small className="mb-0">0.0%</small>
                      <small className="mb-0">100.0%</small>
                    </div>
                  </div>
                  <div className="col-1 m-0 p-0 d-flex justify-content-end">
                    <h5 className="mb-0">80%</h5>
                  </div>
                </div>
                <div className="row d-flex justify-content-between mx-0 my-4 p-0">
                  <div className="col-1 d-flex justify-content-start m-0 p-0">
                    <h5 className="mb-0">5 Star</h5>
                  </div>
                  <div className="col-9 m-0 p-0">
                    <div className="outerGrayBg">
                      <div className="innerGreenBg" style={{ width: "80%" }}></div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <small className="mb-0">0.0%</small>
                      <small className="mb-0">100.0%</small>
                    </div>
                  </div>
                  <div className="col-1 m-0 p-0 d-flex justify-content-end">
                    <h5 className="mb-0">80%</h5>
                  </div>
                </div>
                <div className="row d-flex justify-content-between mx-0 my-4 p-0">
                  <div className="col-1 d-flex justify-content-start m-0 p-0">
                    <h5 className="mb-0">5 Star</h5>
                  </div>
                  <div className="col-9 m-0 p-0">
                    <div className="outerGrayBg">
                      <div className="innerGreenBg" style={{ width: "80%" }}></div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <small className="mb-0">0.0%</small>
                      <small className="mb-0">100.0%</small>
                    </div>
                  </div>
                  <div className="col-1 m-0 p-0 d-flex justify-content-end">
                    <h5 className="mb-0">80%</h5>
                  </div>
                </div>
                <div className="row d-flex justify-content-between mx-0 my-4 p-0">
                  <div className="col-1 d-flex justify-content-start m-0 p-0">
                    <h5 className="mb-0">5 Star</h5>
                  </div>
                  <div className="col-9 m-0 p-0">
                    <div className="outerGrayBg">
                      <div className="innerGreenBg" style={{ width: "80%" }}></div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <small className="mb-0">0.0%</small>
                      <small className="mb-0">100.0%</small>
                    </div>
                  </div>
                  <div className="col-1 m-0 p-0 d-flex justify-content-end">
                    <h5 className="mb-0">80%</h5>
                  </div>
                </div>
                <div className="row d-flex justify-content-between mx-0 my-4 p-0">
                  <div className="col-1 d-flex justify-content-start m-0 p-0">
                    <h5 className="mb-0">5 Star</h5>
                  </div>
                  <div className="col-9 m-0 p-0">
                    <div className="outerGrayBg">
                      <div className="innerGreenBg" style={{ width: "80%" }}></div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <small className="mb-0">0.0%</small>
                      <small className="mb-0">100.0%</small>
                    </div>
                  </div>
                  <div className="col-1 m-0 p-0 d-flex justify-content-end">
                    <h5 className="mb-0">80%</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 m-0  p-0">
              <div className="reviewMainDiv ms-2">
                <h5>Reviews</h5>

                <div>
                  {[1, 2, 3, 4, 5]?.map((v, i)=>{
                    return(
<div className=" d-flex justify-content-between  reviewBox">
                    <div className="d-flex">
                      <div>
                        <img src="https://cdn-icons-png.flaticon.com/128/3687/3687416.png" className="img-fluid me-3" />
                      </div>
                      <div className="mt-1">
                        <div className="d-flex align-items-center">
                          <div className="d-flex starts">
                            <img src="https://cdn-icons-png.flaticon.com/128/1828/1828884.png" className="img-fluid" />
                            <img src="https://cdn-icons-png.flaticon.com/128/1828/1828884.png" className="img-fluid" />
                            <img src="https://cdn-icons-png.flaticon.com/128/1828/1828884.png" className="img-fluid" />
                            <img src="https://cdn-icons-png.flaticon.com/128/1828/1828961.png" className="img-fluid" />
                            <img src="https://cdn-icons-png.flaticon.com/128/1828/1828961.png" className="img-fluid" />
                          </div>
                          <p className="mb-0">Sandeep Sidhu</p>
                        </div>
                        <div style={{marginTop:"-4px"}}>
                        <small className="">Good Driver</small>
                        </div>
                      
                      </div>
                    </div>
                    <div>
                      <span>1 day ago</span>
                    </div>
                  </div>
                    )
                  })}
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DriverRating;
