import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar";
import { getUserList } from "../../services/SuperAdmin/user.services";
import UserHistory from "../../popups/UserHistory";
function UserList() {
  const [userList, setUserList] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const handleUserList = async () => {
    setShowLoader(true);
    try {
      let response = await getUserList(
        JSON.parse(localStorage.getItem("access_token"))
      );
      if (response?.data?.status == "success") {
        setUserList(response?.data?.users);
      } else {
        console.log("Something get wrong");
      }
    } catch (error) {
      console.log(error);
    }
    setShowLoader(false);
  };
  useEffect(() => {
    handleUserList();
  }, []);
  const statics = [
    {
      title: "Users",
      count: 42,
      mainIcon: "/images/user_1.png",
      postive: true,
      percentage: "+15%",
      percentageColor: "red",
    },
    {
      title: "Drivers",
      count: 40,
      mainIcon: "/images/driver.png",
      postive: true,
      percentage: "+15%",
      percentageColor: "blue",
    },
    {
      title: "Total Monthly Earning",
      count: "$250",
      mainIcon: "/images/money.png",
      postive: true,
      percentage: "+15%",
      percentageColor: "orange",
    },
    {
      title: "My Monthly Earning",
      count: "$20",
      mainIcon: "/images/earnings.png",
      postive: true,
      percentage: "+15%",
      percentageColor: "navy",
    },
    {
      title: "Today's Intrac E-Transfer ",
      count: "$50",
      mainIcon: "/images/transfer.png",
      postive: true,
      percentage: "+15%",
      percentageColor: "orangered",
    },
    {
      title: "Today's Quick Deposite",
      count: "$100",
      mainIcon: "/images/time.png",
      postive: true,
      percentage: "+15%",
      percentageColor: "skyblue",
    },
  ];
  const [userHistoryPopup, setUserHistoryPopup] = useState({
    show: false,
    data: null,
  });
  return (
    <div className="userListSection">
      {/* upper section start */}
      <div className="row m-0 p-0">
        <div className="col-5 m-0 p-0">
          <div className="searchBox d-flex">
            <img src="https://cdn-icons-png.flaticon.com/128/751/751463.png" />
            <input placeholder="Search" />
          </div>
        </div>
        <div className="col-7 m-0 p-0 row">
          <div className="col-4">
            <div className="filterBox d-flex ">
              <img src="https://cdn-icons-png.flaticon.com/128/1159/1159641.png" />
              <select>
                <option>Filter</option>
              </select>
            </div>
          </div>
          <div className="col-2 my-auto">
            <div className="d-flex justify-content-between showSelectDiv align-items-center">
              <p className="mb-0">Show</p>
              <select>
                <option>10</option>
              </select>
            </div>
          </div>
          <div className="col-2  d-flex align-items-center justify-content-center my-auto">
            <div className="justify-content-center align-items-center ">
              <p className="mb-0 entriesText">Entiries</p>
            </div>
          </div>
          <div className="col-4">
            <div className="addUserBtn d-flex justify-content-between align-items-center">
              <p className="mb-0">Add User</p>
              <img src="https://cdn-icons-png.flaticon.com/128/1077/1077114.png" />
            </div>
          </div>
        </div>
      </div>
      {/* upper section end */}

      {/* table start */}
      <div className="row tableHeader m-0 mt-5 p-0 px-1">
        <div className="col-7 row  m-0 p-0">
          <div className="col-1 p-1 d-flex justify-content-center">
            <h6 className="mb-0">Sr.No</h6>
          </div>

          <div className="row m-0 p-0 col-11">
            <div className="col-2 p-1 d-flex justify-content-center">
              <h6 className="mb-0">Profile Picture</h6>
            </div>
            <div className="col-2 p-1 d-flex justify-content-center">
              <h6 className="mb-0">First Name</h6>
            </div>
            <div className="col-2 p-1 d-flex justify-content-center">
              <h6 className="mb-0">Last Name</h6>
            </div>
            <div className="col-3 p-1 d-flex justify-content-center">
              <h6 className="mb-0">Email Address</h6>
            </div>
            <div className="col-3 p-1 d-flex justify-content-center">
              <h6 className="mb-0 ">Phone Number</h6>
            </div>
          </div>
        </div>
        <div className="col-5 row  m-0 p-0">
          <div className="col-2 p-1 d-flex justify-content-center">
            <h6 className="mb-0">Wallet</h6>
          </div>
          <div className="col-2 p-1 d-flex justify-content-center">
            <h6 className="mb-0">Status</h6>
          </div>
          <div className="col-2 p-1 d-flex justify-content-center">
            <h6 className="mb-0">Password</h6>
          </div>
          <div className="col-2 p-1 d-flex justify-content-center">
            <h6 className="mb-0">History</h6>
          </div>
          <div className="col-2 p-1 d-flex justify-content-center">
            <h6 className="mb-0">Funds</h6>
          </div>
          <div className="col-2 p-1 d-flex justify-content-center">
            <h6 className="mb-0">Manager</h6>
          </div>
        </div>
      </div>
      {showLoader ? (
        <div className="justify-content-center d-flex vh50 align-items-center">
          <div class="spinner-border text-secondary " role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        userList?.map((v, i) => {
          return (
            <div className="row tableRow m-0 my-3 p-0 px-1">
              <div className="col-7 row  m-0 p-0">
                <div className="col-1 p-1 d-flex justify-content-center my-auto">
                  <p className="mb-0">{i + 1}</p>
                </div>

                <div className="row m-0 p-0 col-11">
                  <div className="col-2 p-1 d-flex justify-content-center my-auto">
                    <img
                      src={
                        v?.image
                          ? "https://api.faizah.in/storage/" + v?.image
                          : " https://cdn-icons-png.flaticon.com/128/3177/3177440.png"
                      }
                      className="userProfileImg"
                    />
                  </div>
                  <div className="col-2 p-1 d-flex justify-content-center my-auto">
                    <p className="mb-0">{v?.first_name}</p>
                  </div>
                  <div className="col-2 p-1 d-flex justify-content-center my-auto">
                    <p className="mb-0">{v?.last_name}</p>
                  </div>
                  <div className="col-3 p-1 d-flex justify-content-center my-auto">
                    <p className="mb-0">{v?.email}</p>
                  </div>
                  <div className="col-3 p-1 d-flex justify-content-center my-auto">
                    <p className="mb-0 ">{v?.contact}</p>
                  </div>
                </div>
              </div>
              <div className="col-5 row  m-0 p-0">
                <div className="col-2 p-1 d-flex justify-content-center my-auto">
                  <p className="mb-0">${v?.user_wallet}</p>
                </div>
                <div className="col-2 p-1 d-flex justify-content-center my-auto">
                  <div className="statusSelectBtn d-flex align-items-center ">
                    {v?.status ? (
                      <div className="redOuter d-flex justify-content-center align-items-center">
                        <div className="redDot"></div>
                      </div>
                    ) : (
                      <div className="greenOuter d-flex justify-content-center align-items-center ">
                        <div className="greenDot"></div>
                      </div>
                    )}

                    <div>
                      <select
                        className={v?.status ? " textDanger" : " textSuccess"}
                      >
                        <option>Active</option>
                        <option>InActive</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-2 p-1 ">
                  <div className="text-center">
                    <img
                      src="https://cdn-icons-png.flaticon.com/128/2767/2767146.png"
                      style={{ height: "14px" }}
                    />
                  </div>
                  <p className="mb-0 text-center">{v?.password}</p>
                </div>
                <div className="col-2 p-1 d-flex justify-content-center my-auto">
                  <button className="btn purpleBtn btn-sm" onClick={()=>setUserHistoryPopup({
                    show:true,
                    data:v
                  })}>View</button>
                </div>
                <div className="col-2 p-1 d-flex justify-content-center my-auto">
                  <button className="btn purpleBtn btn-sm">Open</button>
                </div>
                <div className="col-2 p-1 d-flex justify-content-center my-auto">
                  <select className="managerSelect">
                    <option>Edit</option>
                    <option>Delete</option>
                  </select>
                </div>
              </div>
            </div>
          );
        })
      )}
      <UserHistory userHistoryPopup={userHistoryPopup} setUserHistoryPopup={setUserHistoryPopup} />
      {/* table end */}
    </div>
  );
}

export default UserList;
