import React from "react";
import Sidebar from "../../components/Sidebar";
import { motion } from "framer-motion";

function SuperDashboard() {
  const statics = [
    {
      title: "Users",
      count: 42,
      mainIcon: "/images/user_1.png",
      postive: true,
      percentage: "+15%",
      percentageColor: "red",
    },
    {
      title: "Drivers",
      count: 40,
      mainIcon: "/images/driver.png",
      postive: true,
      percentage: "+15%",
      percentageColor: "blue",
    },
    {
      title: "Total Monthly Earning",
      count: "$250",
      mainIcon: "/images/money.png",
      postive: true,
      percentage: "+15%",
      percentageColor: "orange",
    },
    {
      title: "My Monthly Earning",
      count: "$20",
      mainIcon: "/images/earnings.png",
      postive: true,
      percentage: "+15%",
      percentageColor: "navy",
    },
    {
      title: "Today's Intrac E-Transfer ",
      count: "$50",
      mainIcon: "/images/transfer.png",
      postive: true,
      percentage: "+15%",
      percentageColor: "orangered",
    },
    {
      title: "Today's Quick Deposite",
      count: "$100",
      mainIcon: "/images/time.png",
      postive: true,
      percentage: "+15%",
      percentageColor: "skyblue",
    },
  ];
  const handleLogout = () => {
    const confirmLogout = window.confirm("Are you sure you want to log out?");
    
    if (confirmLogout) {
      localStorage.removeItem("access_token");
      localStorage.removeItem("mieRideUser");
      window.location.reload();
    }
  };
  
  return (
    <div className="homeSection">
      {/* header section start */}
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-end">
          <h6 className="mb-0 me-2">Welcome</h6>
          <h5 className="mb-0">Sandeep Singh !</h5>
        </div>
        <div className="d-flex align-items-center ">
          <div className="stateSelect">
            <select>
              <option>Ontario</option>
              <option>Ontario</option>
              <option>Ontario</option>
              <option>Ontario</option>
            </select>
          </div>
          <div className="profileAvatar">
            <img src="/images/ellipse_1.png" />
          </div>
          <div className="logoutIcon" onClick={() => handleLogout()}>
            <img src="/images/turn-off.png" />
          </div>
        </div>
      </div>
      {/* header section end */}

      {/* statics section start */}
      <div className="row-wrapper" style={{ overflowX: "auto" }}>
        <div className="row p-0 m-0 mt-4 flex-nowrap">
          {statics?.map((v, i) => {
            return (
              <div
                key={i}
                className={"col-auto m-0 p-0" + (i == 5 ? " " : " pe-3")}
              >
                <div className={"staticsCardBox py-3 px-2"}>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="mb-0">{v?.title}</p>
                    <img src={v?.mainIcon} alt="icon" />
                  </div>
                  <h5 className="" style={{ color: v?.percentageColor }}>
                    {v?.count}
                  </h5>
                  <div className="d-flex align-items-center">
                    <img
                      className="bgSuccess rounded"
                      src="/images/positiveStatics.png"
                      alt="positive"
                    />
                    <small className="">10% from last month</small>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/* statics section end */}

      {/* graph section start */}

      <div className="row m-0 p-0 mt-4">
        <div className="col-lg-5 p-0 m-0">
          <div className=" bookingGraph p-4 shadow">
            <div className="d-flex justify-content-between">
              <h6 className="mb-0">Booking Overview</h6>
              <div>
                <select className="">
                  <option>Total</option>
                  <option>Completed</option>
                  <option>Cancelled</option>
                </select>
                <select className="">
                  <option>This Month</option>
                  <option>Todays</option>
                  <option>This year</option>
                  <option>Lifetime</option>
                </select>
              </div>
            </div>
            <div className="row m-0 mt-4 p-0">
              <div className="col-1 m-0 p-0">
                {[10, 9, 8, 7, 6, 5, 4, 3, 2, 1]?.map((v, i) => {
                  return (
                    <p
                      className="mb-0 text-end "
                      style={{
                        height: "33px",
                        position: "relative",
                        bottom: "5px",
                        fontSize: "12px",
                        fontWeight: "500",
                      }}
                    >
                      {v * 100}
                    </p>
                  );
                })}
                <p
                  className="mb-0 text-end "
                  style={{
                    height: "33px",
                    position: "relative",
                    bottom: "12px",
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                >
                  0
                </p>
              </div>
              <div className="col-11 row m-0 p-0">
                {[1, 2, 4, 3, 5, 8, 9, 10, 11, 7, 8, 12]?.map((v, i) => {
                  return (
                    <div className="col-1 ">
                      <div
                        style={{
                          height: "330px",
                          width: "22px",
                          borderRadius: "8px",
                          background: "#F5F5F5",
                        }}
                        className="d-flex align-items-end"
                      >
                        <div
                          className=""
                          style={{
                            height: v * 10 + "px",
                            width: "22px",
                            borderRadius: "8px",
                            background: "#139F01",
                          }}
                        ></div>
                      </div>
                    </div>
                  );
                })}
                {[
                  "Jan",
                  "Feb",
                  "Mar",
                  "Apr",
                  "May",
                  "Jun",
                  "Jul",
                  "Aug",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dec",
                ]?.map((v, i) => {
                  return (
                    <div className="col-1 ">
                      <div
                        style={{ width: "22px" }}
                        className="d-flex align-items-end justify-content-center"
                      >
                        <p style={{ fontSize: "10px" }}>{v}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="row col-lg-7 p-0 m-0 ">
          <div className="col-lg-6 m-0 p-0 px-4">
            <div className="shadow-sm pieGraph  p-4">
              <div className="d-flex justify-content-between">
                <h6 className="mb-0">Category Breakdown</h6>
                <div>
                  <select className="shadow-sm me-2">
                    <option>Daily</option>
                    <option>Weekly</option>
                    <option>Cancelled</option>
                  </select>
                </div>
              </div>
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100%" }}
              >
                <img
                  className="img-fluid"
                  src="/images/vector_smart_object.png"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 m-0 p-0">
            <div className="shadow-sm tasksRemainer  p-4">
              <div className="d-flex  btnGroup">
                <div className="toggleBtn activeToggle">Taks</div>
                <div className="toggleBtn">Remainder</div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row m-0 p-0">
        <div className="col-lg-6  m-0 p-2">
          <div className="shadow-sm bookingGraph p-4">
            <div className="row m-0 p-0">
              <div className="col-1   ">
                <div className="outOfAreaText">Out Of Area</div>
              </div>
              <div className="col-2">
                <div className="outAreaCard border-sm">
                  <div className="text-center upperCap">Bramton to Bramton</div>
                  <div className="text-center lowerCap">Total-2</div>
                </div>
              </div>
              <div className="col-2">
                <div className="outAreaCard border-sm">
                  <div className="text-center upperCap">Bramton to Bramton</div>
                  <div className="text-center lowerCap">Total-2</div>
                </div>
              </div>
              <div className="col-2">
                <div className="outAreaCard border-sm">
                  <div className="text-center upperCap">Bramton to Bramton</div>
                  <div className="text-center lowerCap">Total-2</div>
                </div>
              </div>
              <div className="col-2">
                <div className="outAreaCard border-sm">
                  <div className="text-center upperCap">Bramton to Bramton</div>
                  <div className="text-center lowerCap">Total-2</div>
                </div>
              </div>
              <div className="col-2">
                <div className="outAreaCard border-sm">
                  <div className="text-center upperCap">Bramton to Bramton</div>
                  <div className="text-center lowerCap">Total-2</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* graph section end */}
    </div>
  );
}

export default SuperDashboard;
