import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Login from "./pages/Authentication/Login"
import SuperAdminLayout from './layouts/SuperAdminLayout';
function App() {
  const user = true
  return (
    localStorage.getItem("mieRideUser") ? <SuperAdminLayout/> : <Login/> 
  );
}

export default App;
