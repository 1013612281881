import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location.pathname);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const navItems = [
    {
      title: "Dashboard",
      img: "/images/dashboard_1.png",
      path: "/",
    },
    {
      title: "Command Center",
      img: "/images/exam_copy.png",
      path: "/command-center",
      subMenu: [
        {
          title: "Role List",
          path: "/role-list",
        },
        
        {
          title: "Role Manager",
          path: "/command-center",
        },
        {
          title: "Assign Role",
          path: "/assign-role",
        },
        {
          title: "Manager Option",
          path: "/command-center",
        },
      ],
    },
    {
      title: "Booking Dashboard",
      img: "/images/dashboard_1.png",
      path: "/booking-dashboard",
    },
    {
      title: "Chat Support",
      img: "/images/customer-support.png",
      path:"/chat-support"
    },
    {
      title: "Funds Management",
      img: "/images/money-bag.png",
      path:"/funds-management"
    },

    {
      title: "Ad Control Panel",
      img: "/images/settings_1.png",
      path:"/add-control-center"
    },
    {
      title: "Pricing & Cities",
      img: "/images/file.png",
      path: "/price-city-rate-sharing",
    },
    {
      title: "User",
      img: "/images/customer-support.png",
      path: "/user-list",
    },
    {
      title: "Driver",
      img: "/images/customer-support.png",
      path: "/driver-list",
    },
    {
      title: "Reports",
      img: "/images/google-docs.png",
      path:"/chat-support"
    },
    {
      title: "Support Center",
      img: "/images/live-chat.png",
      path:"/chat-support"
    },
    {
      title: "Option 1",
      img: "/images/live-chat.png",
      path:"/chat-support"
    },
    {
      title: "Option 2",
      img: "/images/live-chat.png",
      path:"/chat-support"
    },
    {
      title: "Option 3",
      img: "/images/live-chat.png",
      path:"/chat-support"
    },
  ];
  return (
    <div className="sideBarLayout">
        <div className="d-flex align-items-center">
          <div className="brandLogo">
            <img src="/images/brandLogo.jpg" />
          </div>
          <div className="brandName">
            {" "}
            <img src="/images/mie_ride.png" />
          </div>
        </div>
        {/* sidebar list start */}
        <div className="navTable">
          {navItems?.map((v, i) => {
            if (v?.subMenu) {
              return (
                <>
                  <div
                    onClick={() => setShowSubMenu(!showSubMenu)}
                    className={
                      "d-flex  justify-content-between  sideBarOption align-items-center mb-3 " +
                      (showSubMenu && "selectedBtn")
                    }
                  >
                    <div className="d-flex w-100">
                      <img className="img-fluid" src={v?.img} />
                      <div className="d-flex justify-content-between w-100   align-items-center ">
                        <p className="mb-0">{v?.title}</p>
                        <div>
                          {v?.subMenu && (
                            <img
                              className=""
                              onClick={() => setShowSubMenu(!showSubMenu)}
                              src="https://cdn-icons-png.flaticon.com/128/8213/8213476.png"
                              style={{
                                height: "15px",
                                width: "15px",
                                position: "relative",
                                left: "20px",
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {showSubMenu && (
                    <div className="sebMenuDiv">
                      {v?.subMenu?.map((v, i) => {
                        return <p onClick={()=>{ navigate(v?.path)}}>{v?.title}</p>;
                      })}
                    </div>
                  )}
                </>
              );
            } else {
              return (
                <>
                  <div
                    onClick={() =>{setShowSubMenu(false); navigate(v?.path)} }
                    className={
                      "d-flex  justify-content-between  sideBarOption align-items-center mb-3 " +
                      (location.pathname == v?.path && !showSubMenu && "selectedBtn")
                    }
                  >
                    <div className="d-flex">
                      <img className="img-fluid" src={v?.img} />
                      <div className="d-flex justify-content-center   align-items-center ">
                        <p className="mb-0">{v?.title}</p>
                      </div>
                    </div>
                  </div>
                </>
              );
            }
          })}
        </div>
        {/* sidebar list end */}
      </div>
  );
}

export default Sidebar;
