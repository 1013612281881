import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar";
import { getDriverList } from "../../services/SuperAdmin/driver.service";
import UserHistory from "../../popups/UserHistory";
import { useNavigate } from "react-router-dom";
function DriverList() {
  const navigate = useNavigate()
  const [driverList, setDriverList] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const handleDriverList = async () => {
    setShowLoader(true);
    try {
      let response = await getDriverList(JSON.parse(localStorage.getItem("access_token")));
      if (response?.data?.status == "success") {
        setDriverList(response?.data?.data);
      } else {
        console.log("Something get wrong");
      }
    } catch (error) {
      console.log(error);
    }
    setShowLoader(false);
  };
  useEffect(() => {
    handleDriverList();
  }, []);
  const statics = [
    {
      title: "Users",
      count: 42,
      mainIcon: "/images/user_1.png",
      postive: true,
      percentage: "+15%",
      percentageColor: "red",
    },
    {
      title: "Drivers",
      count: 40,
      mainIcon: "/images/driver.png",
      postive: true,
      percentage: "+15%",
      percentageColor: "blue",
    },
    {
      title: "Total Monthly Earning",
      count: "$250",
      mainIcon: "/images/money.png",
      postive: true,
      percentage: "+15%",
      percentageColor: "orange",
    },
    {
      title: "My Monthly Earning",
      count: "$20",
      mainIcon: "/images/earnings.png",
      postive: true,
      percentage: "+15%",
      percentageColor: "navy",
    },
    {
      title: "Today's Intrac E-Transfer ",
      count: "$50",
      mainIcon: "/images/transfer.png",
      postive: true,
      percentage: "+15%",
      percentageColor: "orangered",
    },
    {
      title: "Today's Quick Deposite",
      count: "$100",
      mainIcon: "/images/time.png",
      postive: true,
      percentage: "+15%",
      percentageColor: "skyblue",
    },
  ];
  const [userHistoryPopup, setUserHistoryPopup] = useState({
    show: false,
    data: null,
  });
  return (
    <div className="userListSection">
      {/* upper section start */}
      <div className="row m-0 p-0">
        <div className="col-5 m-0 p-0">
          <div className="searchBox d-flex" style={{background:"#FDEEE7"}}>
            <img src="https://cdn-icons-png.flaticon.com/128/751/751463.png" />
            <input placeholder="Search" style={{background:"#FDEEE7"}}/>
          </div>
        </div>
        <div className="col-7 m-0 p-0 row">
          <div className="col-4">
            <div className="filterBox d-flex " style={{background:"#FDEEE7"}}>
              <img src="https://cdn-icons-png.flaticon.com/128/1159/1159641.png" />
              <select style={{background:"#FDEEE7"}}>
                <option>Filter</option>
              </select>
            </div>
          </div>
          <div className="col-2 my-auto">
            <div className="d-flex justify-content-between showSelectDiv align-items-center">
              <p className="mb-0">Show</p>
              <select style={{background:"#FDEEE7"}}>
                <option>10</option>
              </select>
            </div>
          </div>
          <div className="col-2  d-flex align-items-center justify-content-center my-auto">
            <div className="justify-content-center align-items-center ">
              <p className="mb-0 entriesText">Entiries</p>
            </div>
          </div>
          <div className="col-4">
            <div className="addUserBtn d-flex justify-content-between align-items-center" style={{background:"#EC5C13"}}>
              <p className="mb-0">Add Driver</p>
              <img src="https://cdn-icons-png.flaticon.com/128/1077/1077114.png" />
            </div>
          </div>
        </div>
      </div>
      {/* upper section end */}

      {/* table start */}
      <div className="tableWidthFit">
        <div className="row tableHeader  m-0 mt-5 p-0 px-1" style={{background:"#EC5C13", color:"white", borderRadius:"6px"}}>
          <div className="col-5 row  m-0 p-0">
            <div className="col-1 p-1 d-flex justify-content-center">
              <h6 className="mb-0">Sr.No</h6>
            </div>

            <div className="row m-0 p-0 col-11">
              <div className="col-3 p-1 d-flex justify-content-center">
                <h6 className="mb-0">Profile Picture</h6>
              </div>
              <div className="col-3 p-1 d-flex justify-content-center">
                <h6 className="mb-0">First Name</h6>
              </div>
              <div className="col-3 p-1 d-flex justify-content-center">
                <h6 className="mb-0">Last Name</h6>
              </div>
              <div className="col-3 p-1 d-flex justify-content-center">
                <h6 className="mb-0">Email Address</h6>
              </div>
            </div>
          </div>
          <div className="col-7 row  m-0 p-0">
            <div className="col-2 p-1 d-flex justify-content-center">
              <h6 className="mb-0 ">Phone Number</h6>
            </div>
            <div className="col-1 p-1 d-flex justify-content-center">
              <h6 className="mb-0">Wallet</h6>
            </div>
            <div className="col-2 p-1 d-flex justify-content-center">
              <h6 className="mb-0">Vehicle Brand</h6>
            </div>
            <div className="row col-5 m-0 p-0">
              <div className="col-3 p-1 d-flex justify-content-center">
                <h6 className="mb-0">Vehicle Color</h6>
              </div>
              <div className="col-6 p-1 d-flex justify-content-center">
                <h6 className="mb-0">Year Of Manufacture</h6>
              </div>
              <div className="col-3 p-1 d-flex justify-content-center">
                <h6 className="mb-0">Vehicle Size</h6>
              </div>
            </div>
            <div className="col-2 p-1 d-flex justify-content-center">
              <h6 className="mb-0">Status</h6>
            </div>
          </div>
        </div>
        {showLoader ? (
          <div className="justify-content-center d-flex vh50 align-items-center">
            <div class="spinner-border text-secondary " role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          driverList?.map((v, i) => {
            return (
              <div className="row tableRow hoverBg m-0 my-3 py-1 p-0 px-1" onClick={()=>navigate("/driver-profile/"+v?.id)}>
                <div className="col-5 row  m-0 p-0">
                  <div className="col-1 p-1 d-flex justify-content-center my-auto">
                    <p className="mb-0">{i + 1}</p>
                  </div>

                  <div className="row m-0 p-0 col-11">
                    <div className="col-3 p-1 d-flex justify-content-center my-auto">
                      <img
                        src={
                          v?.image
                            ? "https://api.faizah.in/storage/" + v?.image
                            : " https://cdn-icons-png.flaticon.com/128/3177/3177440.png"
                        }
                        className="userProfileImg"
                      />
                    </div>
                    <div className="col-3 p-1 d-flex justify-content-center my-auto">
                      <p className="mb-0">{v?.first_name}</p>
                    </div>
                    <div className="col-3 p-1 d-flex justify-content-center my-auto">
                      <p className="mb-0">{v?.last_name}</p>
                    </div>
                    <div className="col-3 p-1 d-flex justify-content-center my-auto">
                      <p className="mb-0">{v?.email}</p>
                    </div>
                  </div>
                </div>
                <div className="col-7 row  m-0 p-0">
                  <div className="col-2 p-1 d-flex justify-content-center my-auto" >
                    <p className="mb-0 ">{v?.contact}</p>
                  </div>
                  <div className="col-1 p-1 d-flex justify-content-center my-auto">
                    <p className="mb-0">${v?.wallet_balance}</p>
                  </div>
                  <div className="col-2 p-1 d-flex justify-content-center my-auto">
                    <p className="mb-0">{v?.vehicle_brand}</p>
                  </div>
                  <div className="row col-5 m-0 p-0">
                    <div className="col-3 p-1 d-flex justify-content-center my-auto">
                      <p className="mb-0">{v?.vehicle_colour}</p>
                    </div>
                    <div className="col-6 p-1 d-flex justify-content-center my-auto">
                      <p className="mb-0">{v?.vehicle_date}</p>
                    </div>
                    <div className="col-3 p-1 d-flex justify-content-center my-auto">
                      <p className="mb-0">{v?.vehicle_size}</p>
                    </div>
                  </div>
                  <div className="col-2 p-1 d-flex justify-content-center my-auto">
                    <button className="btn btn-sm btn-primary" style={{background: v?.status=="Disapprove" ? "#024596" : v?.status==""? "#139F02" : "#E6220C" }}>{v?.status=="Disapprove" ? "New Request" : v?.status=="Approve"? "Pending" : "Approved"}</button>
                  </div>
                </div>
              </div>
            );
            return (
              <div className="row tableRow m-0 my-3 p-0 px-1">
                <div className="col-5 row  m-0 p-0">
                  <div className="col-1 p-1 d-flex justify-content-center my-auto">
                    <p className="mb-0">{i + 1}</p>
                  </div>

                  <div className="row m-0 p-0 col-11">
                    <div className="col-3 p-1 d-flex justify-content-center my-auto">
                      <img
                        src={
                          v?.image
                            ? "https://api.faizah.in/storage/" + v?.image
                            : " https://cdn-icons-png.flaticon.com/128/3177/3177440.png"
                        }
                        className="userProfileImg"
                      />
                    </div>
                    <div className="col-3 p-1 d-flex justify-content-center my-auto">
                      <p className="mb-0">{v?.first_name}</p>
                    </div>
                    <div className="col-3 p-1 d-flex justify-content-center my-auto">
                      <p className="mb-0">{v?.last_name}</p>
                    </div>
                    <div className="col-3 p-1 d-flex justify-content-center my-auto">
                      <p className="mb-0">{v?.email}</p>
                    </div>
                  </div>
                </div>
                <div className="col-7 row  m-0 p-0">
                  <div className="col-2 p-1 d-flex justify-content-center my-auto">
                    <p className="mb-0 ">{v?.contact}</p>
                  </div>
                  <div className="col-2 p-1 d-flex justify-content-center my-auto">
                    <p className="mb-0">{v?.user_wallet} $230</p>
                  </div>
                  <div className="col-2 p-1 d-flex justify-content-center my-auto">
                    <div className="statusSelectBtn d-flex align-items-center ">
                      <p className="mb-0">{v?.vehicle_brand}</p>
                    </div>
                  </div>
                  <div className="col-1 p-1 ">
                    <p className="mb-0 text-center">{v?.vehicle_colour}</p>
                  </div>
                  <div className="col-2 p-1 d-flex justify-content-center my-auto">
                    <p></p>
                  </div>
                  <div className="col-1 p-1 d-flex justify-content-center my-auto">
                    <p>{v?.vehicle_size}</p>
                  </div>
                  <div className="col-2 p-1 d-flex justify-content-center my-auto">
                    <button className="btn btn-sm btn-primary">New Request</button>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
      <UserHistory userHistoryPopup={userHistoryPopup} setUserHistoryPopup={setUserHistoryPopup} />
      {/* table end */}
    </div>
  );
}

export default DriverList;
