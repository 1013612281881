import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { login } from "../../services/SuperAdmin/user.services";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Define the validation schema using Yup
const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string().min(6, "Password must be at least 6 characters")
  .required("Password is required"),
});

function Login() {
  const [showLoading, setShowLoading] = useState(false);
  // Initial values for the form
  const initialValues = {
    email: "",
    password: "",
  };

  // Function to handle form submission
  const onSubmit = async (values) => {
    setShowLoading(true);
    try {
      let response = await login(values);
      if (response.data.statusCode == "200") {
        localStorage.setItem(
          "mieRideUser",
          JSON.stringify(response.data.administrator)
        );
        localStorage.setItem(
          "access_token",
          JSON.stringify(response.data.token)
        );
        toast.success(response.data.message);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
      else if(response.data.statusCode == "400"){
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Internal Server Error");
    }
    setShowLoading(false);
  };

  return (
    <div className="loginMianDiv vh-100">
      <div className="row m-0 p-0">
        <div className="col-6 m-0 p-0  my-auto">
          <div className="row m-0 p-0 d-flex justify-content-end">
            <div className="col-9  ">
              <img
                src="/images/logoPageMan.png"
                className="img-fluid"
                alt="Page Logo"
              />
            </div>
          </div>
        </div>
        <div className="col-6 row m-0 p-0 d-flex justify-content-center">
          <div className="col-xl-9 col-lg-9">
            <div className="loginFormBox p-5">
              <div className="logoDivImg d-flex justify-content-center">
                <img
                  src="/images/loginPageLogo.png"
                  className="img-fluid"
                  alt="Brand Logo"
                />
              </div>

              {/* Formik form */}
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({}) => (
                  <Form>
                    <div>
                      <Field
                        type="text"
                        name="email"
                        placeholder="Username/ Email Address"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-danger errorText"
                      />
                    </div>
                    <div>
                      <Field
                        type="password"
                        name="password"
                        placeholder="Password"
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="text-danger errorText"
                      />
                    </div>
                    <div className="d-flex justify-content-end forgetPassword">
                      <p className="mb-0 forgetPassword">Forget Password?</p>
                    </div>
                    <div className="signInBtnDiv d-flex justify-content-center">
                      <button type="submit" className={showLoading && " disabled"} disabled={showLoading}>
                        {showLoading ? "Signing In..." : "Sign In"}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Login;
