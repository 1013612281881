import React, { useState, useEffect } from "react";
import { getRoleList, createNewRole, createNewSubrole, getListOfSubrole, getSubroleList } from "../../services/role.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function RoleList() {
  const [showAddRolePopup, setShowAddRolePopup] = useState(false);
  const [showAddSubRolePopup, setShowAddSubRolePopup] = useState(false);
  const [showListSubRolePopup, setShowListSubRolePopup] = useState(false);
  const [selectedSubRole, setShowSelectedSubRole] = useState();
  const [roleList, setRoleList] = useState([]);
  const [subRoleList, setSubroleList] = useState([]);
  const [role_name, setRole_name] = useState("");
  const [subrole_name, setSubrole_name] = useState("");
  const getHandleRole = async () => {
    try {
      let response = await getRoleList(JSON.parse(localStorage.getItem("access_token")));
      setRoleList(response?.data?.data);
    } catch (error) {}
  };
  useEffect(() => {
    getHandleRole();
  }, []);

  const handleCreateNewRole = async () => {
    if (role_name) {
      try {
        let response = await createNewRole(JSON.parse(localStorage.getItem("access_token")), { role_name });
        if (response.data.message == "Role created successfully") {
          toast.success("Role created successfully");
          setShowAddRolePopup(false);
          setRole_name("");
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        toast.error(error.response?.data?.data?.role_name[0]);
      }
    } else {
    }
  };
  const handleCreateNewSubrole = async () => {
    if (subrole_name) {
      try {
        let response = await createNewSubrole(JSON.parse(localStorage.getItem("access_token")), { role_id: selectedSubRole.id, subrole_name });
        if (response.data.message == "Subrole created successfully") {
          toast.success("Subrole created successfully");
          setShowAddSubRolePopup(false);
          setSubrole_name("");
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        toast.error(error.response?.data?.data?.subrole_name[0]);
      }
    } else {
    }
  };
  const getHandleSubrole = async (id) => {
    try {
      let response = await getSubroleList(JSON.parse(localStorage.getItem("access_token")), id);
      console.log(response?.data?.data)
      setSubroleList(response?.data?.data);
    } catch (error) {}
  };
  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        <h5 className="mb-0">Roles</h5>
        <div className="btn btn-primary" onClick={() => setShowAddRolePopup(!showAddRolePopup)}>
          Add New
        </div>
      </div>
      {showAddRolePopup ? (
        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
          <div style={{ width: "400px" }} className="p-3 rounded shadow-sm">
            <div className="d-flex mb-4 bg-light p-2 justify-content-between align-items-center">
              <h5 className="mb-0">Create Role</h5>
              <img
                onClick={() => setShowAddRolePopup(false)}
                src="https://cdn-icons-png.flaticon.com/128/9068/9068699.png"
                style={{ height: "25px", width: "25px" }}
              />
            </div>

            <label>Name</label>
            <input className="form-control" value={role_name} onChange={(e) => setRole_name(e.target.value)} />
            <button className="btn btn-primary w-100 mt-3" onClick={handleCreateNewRole}>
              Submit
            </button>
          </div>
        </div>
      ) : (
        <>
          <div className="row m-0 mt-2 rounded   bg-light p-2">
            <div className="col-1 m-0 p-0 justify-content-center">
              <div>Sr.No</div>
            </div>
            <div className="col-8 m-0 p-0 justify-content-center">
              <div>Role Name</div>
            </div>
            <div className="col-3 m-0 p-0 d-flex justify-content-end">
              <div>Subrole</div>
            </div>
          </div>
          {roleList?.map((v, i) => {
            return (
              <div className="row m-0 mt-2 rounded shadow-sm p-2">
                <div className="col-1 m-0 p-0 justify-content-center">
                  <div style={{ fontWeight: "400" }}>{i + 1}.</div>
                </div>
                <div className="col-8 m-0 p-0 justify-content-center">
                  <div style={{ fontWeight: "400" }}>{v?.role_name}</div>
                </div>
                <div className="col-3 m-0 p-0 d-flex justify-content-end">
                  <div>
                    <button className="btn btn-warning btn-sm ms-2" onClick={()=>{
                      setShowSelectedSubRole(v);
                      getHandleSubrole(v?.id)
                      setShowListSubRolePopup(true);
                    }}>View</button>
                    <button
                      className="btn btn-success btn-sm ms-2"
                      onClick={() => {
                        setShowSelectedSubRole(v);
                        setShowAddSubRolePopup(true);
                      }}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      )}
      <ToastContainer />
      {showAddSubRolePopup && (
        <div className={`modal fade show`} style={{ display: "block" }} tabIndex={-1}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Add a subrole to {selectedSubRole.role_name}</h5>

                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => setShowAddSubRolePopup(false)}
                />
              </div>
              <div className="modal-body">
                <label>Subrole Name</label>
                <input className="form-control" onChange={(e)=>setSubrole_name(e.target.value)}/>
                <button className="btn btn-primary w-100 mt-3" onClick={handleCreateNewSubrole}>Save</button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showAddSubRolePopup && <div className="modal-backdrop fade show"></div>}


      {showListSubRolePopup && (
        <div className={`modal fade show`} style={{ display: "block" }} tabIndex={-1}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">List of subrole of {selectedSubRole.role_name}</h5>

                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => setShowListSubRolePopup(false)}
                />
              </div>
              <div className="modal-body">
                {subRoleList?.map((v, i)=>{
                  return(
                    <p className="badge bg-primary m-2">{v?.subrole_name}</p>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      )}
      {showListSubRolePopup && <div className="modal-backdrop fade show"></div>}
    </div>
  );
}

export default RoleList;
